
import { Options, Vue } from "vue-class-component";
import HomeNav from "@/components/Home/HomeNav.vue";
import AboutUs from "@/components/Home/AboutUs.vue";
import HomeValues from "@/components/Home/HomeValues.vue";
import HomeFooter from "@/components/Home/Footer.vue";

@Options({
  components: {
    HomeNav,
    AboutUs,
    HomeValues,
    HomeFooter,
  },
})
export default class Home extends Vue {}
