import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_nav = _resolveComponent("home-nav")!
  const _component_about_us = _resolveComponent("about-us")!
  const _component_home_values = _resolveComponent("home-values")!
  const _component_home_footer = _resolveComponent("home-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_home_nav),
    _createVNode(_component_about_us),
    _createVNode(_component_home_values),
    _createVNode(_component_home_footer)
  ]))
}